import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  formatCurrency,
  formatISODate,
  isCreditCardExpDateValid,
  sortByTime,
  useBooleanInput,
} from '@pumpkincare/shared';
import {
  Body1,
  Body2,
  ButtonStyles,
  LegalBody,
  Spinner,
} from '@pumpkincare/shared/ui';
import {
  formatBillingAddressText,
  getPaymentMethodBrand,
  getPaymentMethodExpMonth,
  getPaymentMethodExpYear,
  getPaymentMethodLast4,
  getUserBillingAddress,
  getUserIsChargedAnnually,
  getUserLapsedSince,
  getUserPets,
  PEP_PLAN_STATUS,
  POLICY_STATUS,
  useInvoices,
  useNextCharges,
  usePayment,
  useUserAddresses,
  useUssr,
  WELLNESS_STATUS,
} from '@pumpkincare/user';

import MailingAddressUpdateModal from '../mailing-address-update-modal';
import BillingAddressUpdateModal from './billing-address-update-modal';
import EditPaymentModal from './edit-payment-modal';
import InvoicesModal from './invoices-modal';

import sharedStyles from '../account-wrapper-shared.css';
import styles from './account-billing.css';

export function isAPetLapsed(nextChargePets, userPets, billingFeatureFlag = false) {
  return nextChargePets.some(({ id }) => {
    const pet = userPets.find(userPet => userPet.id === id);
    if (billingFeatureFlag) {
      return (
        pet?.policies.some(policy => policy.status === POLICY_STATUS.LAPSED) ||
        pet?.wellness.some(wellness => wellness.status === WELLNESS_STATUS.LAPSED) ||
        pet?.plans.some(plan => plan.status === PEP_PLAN_STATUS.LAPSED)
      );
    } else {
      return pet?.policies.some(policy => policy.status === POLICY_STATUS.LAPSED);
    }
  });
}

function transformNextChargesData(nextChargesData, pets) {
  const petStatusMap = pets.reduce((map, pet) => {
    if (
      pet.policies.some(policy => policy.status === POLICY_STATUS.LAPSED) ||
      pet.wellness.some(wellness => wellness.status === WELLNESS_STATUS.LAPSED) ||
      pet.plans.some(plan => plan.status === PEP_PLAN_STATUS.LAPSED)
    ) {
      map[pet.id] = 'lapsed';
    }
    return map;
  }, {});

  // Update nextChargesData with pet statuses
  return nextChargesData.reduce((result, charge) => {
    const lapsedPets = [];
    const activePets = [];
    const petProducts = {};

    // Categorize pets and collect products
    charge.pets.forEach(pet => {
      const status = petStatusMap[pet.id] || 'active';
      pet.status = status;

      if (status === 'lapsed') {
        lapsedPets.push(pet);
      } else {
        activePets.push(pet);
      }
    });

    charge.pet_products.forEach(product => {
      if (!petProducts[product.pet_id]) {
        petProducts[product.pet_id] = [];
      }
      petProducts[product.pet_id].push(product);
    });

    // Create objects for lapsed and active pets
    if (lapsedPets.length > 0) {
      result.push({
        ...charge,
        pets: lapsedPets,
        pet_products: lapsedPets.flatMap(pet => petProducts[pet.id] || []),
      });
    }

    if (activePets.length > 0) {
      result.push({
        ...charge,
        pets: activePets,
        pet_products: activePets.flatMap(pet => petProducts[pet.id] || []),
      });
    }

    return result;
  }, []);
}

function sortNextChargeDates(nextCharges) {
  return sortByTime(nextCharges, { key: 'next_charge_date', order: 'asc' });
}

function AccountBilling() {
  const { hash } = useLocation();
  const { punks1457AdminCustomerBilling } = useFlags();
  const { data: userData, isFetching: isUserFetching } = useUssr();
  const pets = getUserPets(userData);
  const isUserLapsed = !!getUserLapsedSince(userData);
  const isChargedAnnually = getUserIsChargedAnnually(userData);

  const { data: addressData, isFetching: isBillingFetching } = useUserAddresses();
  const billingAddress = getUserBillingAddress(addressData);

  const { data: nextChargesData, isFetching: isNextChargesFetching } =
    useNextCharges({ onFetch: sortNextChargeDates });
  const { data: invoicesData } = useInvoices({
    onFetch: sortByTime,
  });

  const { data: paymentData, isFetching: isPaymentFetching } = usePayment();
  const expMonth = getPaymentMethodExpMonth(paymentData);
  const expYear = getPaymentMethodExpYear(paymentData);
  const brand = getPaymentMethodBrand(paymentData);
  const last4 = getPaymentMethodLast4(paymentData);

  const [isPaymentModalOpen, togglePaymentModal] = useBooleanInput(
    hash === '#edit-payment-method'
  );
  const [isBillingAddressModalOpen, toggleBillingAddressModal] =
    useBooleanInput(false);
  const [isMailingAddressModalOpen, toggleMailingAddressModal] =
    useBooleanInput(false);
  const [isInvoicesModalOpen, toggleInvoicesModal] = useBooleanInput(false);

  function handleBillingUpdateSuccess() {
    toggleBillingAddressModal();
    toggleMailingAddressModal();
  }

  const isCreditCardInvalid =
    isUserLapsed && !isCreditCardExpDateValid(expMonth, expYear);

  function getProductDescriptions(input, pet_id) {
    const descriptions = {
      wellness: 'Pumpkin Wellness Club',
      insurance: 'Pumpkin Pet Insurance',
      prevent: 'Preventive Essentials',
    };

    const pet = input.find(pet => pet.pet_id === pet_id);

    if (pet) {
      const productDescriptions = pet.product
        .map(product => descriptions[product] || '')
        .filter(Boolean);

      return productDescriptions.join(' & ');
    }

    return '';
  }

  return (
    <div className={styles.root}>
      <div className={sharedStyles.section}>
        <h5>Payment Method</h5>

        <div className={sharedStyles.cell}>
          {isPaymentFetching ? (
            <Spinner data-testid='payments-spinner' />
          ) : (
            <>
              <div className={sharedStyles.flex}>
                <div className={sharedStyles.topLine}>
                  <Body2 isBold>
                    {brand} **** {last4}
                  </Body2>

                  <button onClick={togglePaymentModal} className={ButtonStyles.cta}>
                    Edit
                  </button>
                </div>

                <Body2
                  className={classNames({ [styles.error]: isCreditCardInvalid })}
                >
                  Expiration {expMonth}/{expYear}
                </Body2>

                {isCreditCardInvalid ? (
                  <LegalBody className={styles.error}>
                    Your credit card has expired. Please update your payment method
                    in order to process your past due payment.
                  </LegalBody>
                ) : null}

                {isPaymentModalOpen ? (
                  <EditPaymentModal handleCloseModal={togglePaymentModal} />
                ) : null}
              </div>
            </>
          )}
        </div>

        <div className={sharedStyles.cell}>
          {isBillingFetching ? (
            <Spinner data-testid='billing-spinner' />
          ) : (
            <>
              <div className={sharedStyles.flex}>
                <div className={sharedStyles.topLine}>
                  <Body2 isBold>Billing Address</Body2>

                  <button
                    onClick={toggleBillingAddressModal}
                    className={ButtonStyles.cta}
                  >
                    Edit
                  </button>
                </div>

                <Body2>{formatBillingAddressText(billingAddress)}</Body2>
              </div>

              {isBillingAddressModalOpen ? (
                <BillingAddressUpdateModal
                  handleCloseModal={toggleBillingAddressModal}
                  billingAddress={{
                    street_1: billingAddress.street_1,
                    street_2: billingAddress.street_2,
                    city: billingAddress.city,
                    state: billingAddress.state_province,
                    zipcode: billingAddress.zipcode,
                    country: billingAddress.country,
                  }}
                  handleOnSuccess={handleBillingUpdateSuccess}
                />
              ) : null}

              {isMailingAddressModalOpen ? (
                <MailingAddressUpdateModal
                  handleCloseModal={toggleMailingAddressModal}
                  header="Is this your pet's new home address too?"
                />
              ) : null}
            </>
          )}
        </div>

        <div className={sharedStyles.cell}>
          <div className={sharedStyles.flex}>
            <div className={sharedStyles.topLine}>
              <Body2 isBold>Billing Cycle</Body2>
            </div>

            <Body2>
              {isChargedAnnually ? 'Annually' : 'Monthly'} (on the billing day of the
              policy)
            </Body2>
          </div>
        </div>
      </div>

      <div className={sharedStyles.section}>
        <h5 className={classNames(styles.sectionHeader, sharedStyles.topLine)}>
          Upcoming Payments
          {invoicesData?.length ? (
            <button
              className={classNames(styles.invoices, ButtonStyles.secondary)}
              onClick={toggleInvoicesModal}
            >
              View monthly statements
            </button>
          ) : null}
        </h5>

        {isNextChargesFetching ? (
          <Spinner classes={{ root: styles.margin }} />
        ) : null}

        {punks1457AdminCustomerBilling && !isNextChargesFetching && nextChargesData
          ? transformNextChargesData(nextChargesData, pets).map(nextCharge => (
              <div className={styles.scheduledItem}>
                <div className={styles.topLine}>
                  {!isUserFetching &&
                  isAPetLapsed(
                    nextCharge.pets,
                    pets,
                    punks1457AdminCustomerBilling
                  ) ? (
                    <LegalBody className={styles.lapsed}>Lapsed</LegalBody>
                  ) : null}
                  <Body1 isBold className={styles.scheduledDate}>
                    Payments scheduled{' '}
                    {formatISODate(nextCharge.next_charge_date, {
                      format: 'MMM D, YYYY',
                      isGenerated: true,
                    })}
                  </Body1>
                </div>

                {nextCharge.pets.map(({ name, id }) => (
                  <LegalBody className={styles.petProduct}>
                    <b>{name}</b>{' '}
                    {getProductDescriptions(nextCharge?.pet_products, id)}
                  </LegalBody>
                ))}

                {nextCharge.past_due ? (
                  <div className={styles.upcomingValue}>
                    <Body2>
                      Past Due{' '}
                      <b>
                        {formatCurrency(nextCharge.past_due, { areCents: true })}
                      </b>
                    </Body2>
                  </div>
                ) : null}

                {nextCharge.amount ? (
                  <div className={styles.upcomingValue}>
                    <Body1>
                      Upcoming Amount{' '}
                      <b> {formatCurrency(nextCharge.amount, { areCents: true })}</b>
                    </Body1>
                  </div>
                ) : null}
              </div>
            ))
          : null}

        {!punks1457AdminCustomerBilling && !isNextChargesFetching
          ? nextChargesData.map(nextCharge => (
              <div key={nextCharge.next_charge_date} className={sharedStyles.cell}>
                <div className={sharedStyles.flex}>
                  <div className={sharedStyles.topLine}>
                    <Body2 isBold>
                      {nextCharge.pets.map(({ name }) => name).join(' / ')}
                    </Body2>
                    {nextCharge.past_due &&
                    !isUserFetching &&
                    isAPetLapsed(
                      nextCharge.pets,
                      pets,
                      punks1457AdminCustomerBilling
                    ) ? (
                      <LegalBody className={styles.lapsed}>Lapsed</LegalBody>
                    ) : null}
                  </div>

                  {nextCharge.past_due ? (
                    <Body2>
                      Past Due{' '}
                      <b>
                        {formatCurrency(nextCharge.past_due, { areCents: true })}
                      </b>
                    </Body2>
                  ) : null}

                  {nextCharge.next_charge_date ? (
                    <Body2>
                      Next payment scheduled{' '}
                      <b>
                        {formatISODate(nextCharge.next_charge_date, {
                          format: 'MMM D, YYYY',
                          isGenerated: true,
                        })}
                      </b>
                    </Body2>
                  ) : null}

                  <Body2>
                    Next payment amount{' '}
                    <b>{formatCurrency(nextCharge.amount, { areCents: true })}</b>
                  </Body2>
                </div>
              </div>
            ))
          : null}

        {invoicesData && isInvoicesModalOpen ? (
          <InvoicesModal
            handleCloseModal={toggleInvoicesModal}
            invoices={invoicesData}
          />
        ) : null}
      </div>
    </div>
  );
}

export default AccountBilling;
